import React, { useState, useEffect, useRef } from 'react'
import { FormContainer, Hero } from '@truphone/core-components'
import { uppercaseString } from '@truphone/utils'
import { PrimaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'

export default function GetStarted(props) {
  const [selectedForm, setSelectedForm] = useState(null)
  const formContainerDiv = useRef(null)
  const handleSelectForm = (form) => {
    setSelectedForm(form)
    if (form) {
      window.location = '#' + form.id
      if (formContainerDiv && formContainerDiv.current) {
        window.scrollTo({
          left: 0,
          top: formContainerDiv.current.offsetTop - 60,
          behavior: 'smooth'
        })
      }
    }
  }

  const onSubmitted = () => {
    if (formContainerDiv && formContainerDiv.current) {
      window.scrollTo({
        left: 0,
        top: formContainerDiv.current.offsetTop - 60,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    const hashChange = () => {
      if (window.location.hash === '') {
        setSelectedForm(null)
      }
      let id = window.location.hash ? window.location.hash.substring(1) : props.id

      if (id) {
        let form = props.forms.find((x) => x.id === id)
        handleSelectForm(form)
      }
    }

    hashChange()
    window.addEventListener('hashchange', hashChange, false)

    return () => {
      window.removeEventListener('hashchange', hashChange, false)
    }
  }, [selectedForm])

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto lg:px-6">
        {!selectedForm && (
          <Hero
            topTitle={props.topTitle}
            title={props.title}
            subtitle={props.subtitle}
            textStyle="dark"
            secondaryMenu={true}
            smallPaddingBottomMobile={true}
          />
        )}
        {!selectedForm && props.forms && (
          <div className="flex flex-wrap lg:-mx-4 w-full">
            {props.forms.map((form, i) => {
              return (
                <div
                  key={`contact-form-${i}`}
                  className={`w-full lg:w-1/3 p-4 cursor-pointer hover-effect`}
                  onClick={() => {
                    if (form.cta?.href) {
                      window.location = form.cta?.href
                    } else {
                      handleSelectForm(form)
                    }
                  }}
                >
                  <div
                    className="flex flex-col h-full mx-auto hover:shadow"
                    style={{ minWidth: 300, maxWidth: 380 }}
                  >
                    {form.image && (
                      <div className="shrink-0 w-full h-36 overflow-hidden rounded-t">
                        <ImageWrap
                          className="transition-all image-hover-effect w-full h-full object-cover"
                          {...form.image}
                        />
                      </div>
                    )}
                    <div className="p-8 h-full bg-white flex flex-col">
                      {form.title && <h3 className="text-xl mb-4">{form.title}</h3>}
                      {form.content && (
                        <div
                          className="text-sm list-tick p-margin mb-8"
                          dangerouslySetInnerHTML={{ __html: form.content }}
                        />
                      )}

                      {form.cta && (
                        <PrimaryButton className={`mt-auto text-center`} href={form.cta.href}>
                          {form.cta.text}
                        </PrimaryButton>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {selectedForm && (
          <div className="pt-28">
            <div ref={formContainerDiv} className="text-center pt-20">
              <p className="overline-text uppercase mb-4">{props.title}</p>
              <h1 className={`heron leading-none text-5xl md:text-6xl lg:whitespace-pre-line`}>
                {uppercaseString(selectedForm.title)}
              </h1>
            </div>

            <FormContainer
              {...selectedForm.formContainer}
              title={null}
              anchor={null}
              whiteBox
              onSubmitted={onSubmitted}
            />
          </div>
        )}
      </div>
    </div>
  )
}
